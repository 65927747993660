import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import MaterialThemeProvider from "./providers/theme";
import MuiSnackbarProvider from "./providers/snackbar";
import NotificationProvider from "./providers/notification";
import Web3Provider from "./providers/web3";
import { Provider as ReduxProvider } from "react-redux";

// ** Initialize Store
import configureStore from "./redux/store";

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <ReduxProvider store={store}>
        <MaterialThemeProvider>
          <MuiSnackbarProvider>
            <NotificationProvider>
              <Web3Provider>
                <App />
              </Web3Provider>
            </NotificationProvider>
          </MuiSnackbarProvider>
        </MaterialThemeProvider>
      </ReduxProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
